.masthead-film {
    position: relative;
    overflow: hidden;
    padding-top: calc(7rem + 72px);
    padding-bottom: 7rem;
    background-image: url("../../../public/assets/img/banner.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    background-size: cover;
}


.btn-isthgul{
    color: #fff;
    background-color: #55874d;
    border-color: #55874d;
}